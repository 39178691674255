/**
 * Translates text
 *
 * @param {string} text
 * @return {string}
 */
export function __(text) {
    return (text in Latecoere.translations) ? Latecoere.translations[text] : text;
}

/**
 * @param {*|Array} value
 * @return {Array}
 */
export function arrayWrap(value) {
    return Array.isArray(value) ? value : [value];
}
