import 'chart-stackedbar-js';
import Chart from 'chart.js';
import 'jquery-hoverintent';
import 'jquery-lazyload';
import Swiper from 'swiper';
import {FinanceController} from './controllers/FinanceController';
import {__} from './helpers';
import {Router} from './Router';

require.context('../images/icons', true, /\.svg$/);

window.Chart = Chart;

const router = new Router([
    {
        classes: 'page-template-gab-finances-statistiques',
        controller: FinanceController,
    },
]);

// let bourgetNavItem = document.querySelector('nav #bourget');
// if (bourgetNavItem) {
//     let bourget = new Date(bourgetNavItem.dataset.date);
//     let days = Math.floor((bourget - new Date()) / 1000 / 60 / 60 / 24);
//     bourgetNavItem.innerText = __('J-%d').replace('%d', days) + ' ' + bourgetNavItem.innerText;
// }

jQuery($ => {
    router.start();

    // var compteurBourget;

    /**
     * Initialisation menu principal
     * @type {{over: makeTall, interval: number, out: makeShort}}
     */
    var configMenu = {
        over: makeTall, // function = onMouseOver callback (REQUIRED)
        //timeout: 500, // number = milliseconds delay before onMouseOut
        interval: 150,
        out: makeShort, // function = onMouseOut callback (REQUIRED)
    };

    /**
     * Main menu
     */
    if (!$('html').hasClass('no-touch')) {
        $('.header nav > ul > li').click(function (event) {
            $('.header nav > ul > li').not($(this)).removeClass('actif');
            $('.header nav > ul > li').not($(this)).children('ul.sub-menu').slideUp(100);

            if (!$(this).hasClass('actif')) {
                event.preventDefault();
                $(this).addClass('actif');
                $(this).children('ul.sub-menu').slideDown(400);
            }
        });
    } else {
        $('.header nav ul li').hoverIntent(configMenu);
    }

    /**
     * Fonction show ss-menu
     */
    function makeTall() {
        $(this).addClass('actif');
        $(this).children('ul.sub-menu').slideDown(300);
    }

    /**
     * Fonction hide ss-menu
     */
    function makeShort() {
        $(this).removeClass('actif');
        $(this).children('ul.sub-menu').slideUp(200);
    }

    $('.menu-mobile').click(function () {
        $('.header .menuMain').slideToggle(300);
        $('.header .languages').slideToggle(300);
    });

    function tabSize() {
        var getList = $('.b-onglets ul');

        $('.b-onglets ul li').each(function () {
            $(this).width(getList.width() / getList.find('li').length);
        });
    }

    // function popinBourget() {
    //     $('#popin-bourget').fadeIn();
    //
    //     var message,
    //         jour;
    //     if (window.location.pathname.indexOf('/en/') > -1) {
    //         message = 'Le Bourget';
    //         jour = 'd';
    //     } else {
    //         message = 'Au salon du Bourget';
    //         jour = 'j';
    //         console.log(document.querySelector('#popin-bourget .compteur'));
    //     }
    //
    //     var countDownDate = new Date('Jun 17, 2019 09:00:00').getTime();
    //
    //     compteurBourget = setInterval(function () {
    //         var now = new Date().getTime();
    //
    //         var distance = countDownDate - now;
    //
    //         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //
    //         document.getElementById('jours').innerHTML = '<span>' + days.toLocaleString('en-US', {
    //             minimumIntegerDigits: 2,
    //             useGrouping: false,
    //         }) + '</span>' + jour;
    //         document.getElementById('heures').innerHTML = '<span>' + hours.toLocaleString('en-US', {
    //             minimumIntegerDigits: 2,
    //             useGrouping: false,
    //         }) + '</span>h';
    //         document.getElementById('minutes').innerHTML = '<span>' + minutes.toLocaleString('en-US', {
    //             minimumIntegerDigits: 2,
    //             useGrouping: false,
    //         }) + '</span>m';
    //
    //         if (distance < 0) {
    //             clearInterval(compteurBourget);
    //             document.querySelector('#popin-bourget .compteur').innerHTML = '<div>' + message + '</div>';
    //         }
    //     }, 1000);
    // }

    /**
     * Diaporamas
     */
    $(function () {

        /**
         * Initialsiation loop et autoplay des slider
         */
        var loopValue = true;
        var autoplayValue = 5000;

        if ($('.diapo-home').length && $('.diapo-home .swiper-slide').length == 1) {
            loopValue = false;
            autoplayValue = false;
        }
        if ($('.diapo-presentation').length && $('.diapo-presentation .swiper-slide').length == 1) {
            loopValue = false;
            autoplayValue = false;
        }
        if ($('.diapo-product').length && $('.diapo-product .swiper-slide').length == 1) {
            loopValue = false;
            autoplayValue = false;
        }
        if ($('.diapo-tweet').length && $('.diapo-tweet .swiper-slide').length == 1) {
            loopValue = false;
            autoplayValue = false;
        }
        if ($('.diapo-news-desktop').length && $('.diapo-news-desktop .swiper-slide').length == 1) {
            loopValue = false;
            autoplayValue = false;
        }

        if ($('.diapo-news-mobile').length && $('.diapo-news-mobile .swiper-slide').length == 1) {
            loopValue = false;
            autoplayValue = false;
        }

        var diapoHome;
        window.addEventListener('load', () => {
            /**
             * Diaporama home page
             * @type {Swiper}
             */
            diapoHome = new Swiper('.diapo-home', {
                loop: loopValue,
                calculateHeight: true,
                autoplay: autoplayValue,
                updateOnImagesReady: true,
                onSlideChangeStart: function (swiper) {
                    $('.diapo-home .pager li').removeClass('actif');
                    $('.diapo-home .pager li').eq(swiper.activeLoopIndex).addClass('actif');
                },
            });
        });

        /**
         * Pager diaporama home page
         */
        $('.diapo-home .pager li').click(function () {
            diapoHome.swipeTo($(this).index());
        });

        /**
         * Pager next diaporama home
         */
        $('.diapo-home .pager .page-next').click(function () {
            diapoHome.swipeNext();
        });

        /**
         * Pager prev diaporama home
         */
        $('.diapo-home .pager .page-prev').click(function () {
            diapoHome.swipePrev();
        });

        if ($('body').hasClass('page-template-gab-czech')) {
            tabSize();
        }

        $('.page-template-gab-czech .b-onglets ul li a').click(function () {
            var getHash = $(this)[0].hash;
            if (getHash.charAt(0) === '#') {
                var getTarget = getHash.substr(1);
            }
            $('html, body').animate({
                scrollTop: $('#' + getTarget).offset().top - $('.page-template-gab-czech .b-onglets').height() - $('.header').height(),
            }, 350);
            return false;
        });

        /**
         * Diaporama présentation
         * @type {Swiper}
         */
        let diapoPresentation = new Swiper('.diapo-presentation .swiper-container', {
            loop: loopValue,
            calculateHeight: true,
            autoplay: autoplayValue,
            updateOnImagesReady: true,
        });
        /**
         * Pager next diaporama présentation
         */
        $('.diapo-presentation .page-next').click(function () {
            diapoPresentation.swipeNext();
        });

        /**
         * Pager prev diaporama présentation
         */
        $('.diapo-presentation .page-prev').click(function () {
            diapoPresentation.swipePrev();
        });

        /**
         * Diaporama bu product
         * @type {Swiper}
         */
        let diapoProduct = new Swiper('.diapo-product .swiper-container', {
            loop: loopValue,
            calculateHeight: true,
            autoplay: autoplayValue,
            updateOnImagesReady: true,
            onSlideChangeStart: function (swiper) {
                $('.diapo-product .pager li').removeClass('actif');
                $('.diapo-product .pager li').eq(swiper.activeLoopIndex).addClass('actif');
            },
        });
        /**
         * Pager bu product
         */
        $('.diapo-product .pager li').click(function () {
            diapoProduct.swipeTo($(this).index());
        });

        /**
         * Pager next diaporama bu product
         */
        $('.diapo-product .page-next').click(function () {
            diapoProduct.swipeNext();
        });

        /**
         * Pager prev diaporama bu product
         */
        $('.diapo-product .page-prev').click(function () {
            diapoProduct.swipePrev();
        });

        /**
         * Diaporama tweet
         * @type {Swiper}
         */
        let diapoTweet = new Swiper('.diapo-tweet', {
            loop: loopValue,
            calculateHeight: true,
            autoplay: autoplayValue,
            updateOnImagesReady: true,
        });
        /**
         * Pager next diaporama tweet
         */
        $('.diapo-tweet .page-next').click(function () {
            diapoTweet.swipeNext();
        });

        /**
         * Pager prev diaporama tweet
         */
        $('.diapo-tweet .page-prev').click(function () {
            diapoTweet.swipePrev();
        });

        /**
         * Diaporama News Desktop
         * @type {Swiper}
         */
        var diapoNewsDesktop = new Swiper('.diapo-news-desktop .swiper-container', {
            loop: loopValue,
            calculateHeight: true,
            autoplay: autoplayValue,
            updateOnImagesReady: true,
            onSlideChangeStart: function (swiper) {
                $('.diapo-news-desktop .pager li').removeClass('actif');
                $('.diapo-news-desktop .pager li').eq(swiper.activeLoopIndex).addClass('actif');
            },
        });

        /**
         * Pager diaporama News Desktop
         */
        $('.diapo-news-desktop .pager li').click(function () {
            diapoNewsDesktop.swipeTo($(this).index());
        });

        /**
         * Pager next diaporama Desktop
         */
        $('.diapo-news-desktop .page-next').click(function () {
            diapoNewsDesktop.swipeNext();
        });

        /**
         * Pager prev diaporama Desktop
         */
        $('.diapo-news-desktop .page-prev').click(function () {
            diapoNewsDesktop.swipePrev();
        });

        /**
         * Diaporama News Mobile
         * @type {Swiper}
         */
        var diapoNewsMobile = new Swiper('.diapo-news-mobile .swiper-container', {
            loop: loopValue,
            calculateHeight: true,
            autoplay: autoplayValue,
            updateOnImagesReady: true,
            onSlideChangeStart: function (swiper) {
                $('.diapo-news-mobile .pager li').removeClass('actif');
                $('.diapo-news-mobile .pager li').eq(swiper.activeLoopIndex).addClass('actif');
            },
        });

        /**
         * Pager diaporama News Mobile
         */
        $('.diapo-news-mobile .pager li').click(function () {
            diapoNewsMobile.swipeTo($(this).index());
        });

        /**
         * Pager next diaporama Mobile
         */
        $('.diapo-news-mobile .page-next').click(function () {
            diapoNewsMobile.swipeNext();
        });

        /**
         * Pager prev diaporama Mobile
         */
        $('.diapo-news-mobile .page-prev').click(function () {
            diapoNewsMobile.swipePrev();
        });

        $(window).resize(function () {
            if ($('.diapo-home').length) {
                diapoHome.reInit();
            }
            if ($('.diapo-presentation').length) {
                diapoPresentation.reInit();
            }
            if ($('.diapo-product').length) {
                diapoProduct.reInit();
            }
            if ($('.diapo-tweet').length) {
                diapoTweet.reInit();
            }
            if ($('.diapo-news-desktop').length) {
                diapoNewsDesktop.reInit();
            }
            if ($('.diapo-news-mobile').length) {
                diapoNewsMobile.reInit();
            }
            if ($(window).width() > 1023) {
                $('.header nav').removeAttr('style');
            }

            tabSize();

        });

    });

    /**
     * Affichage détail product
     */
    $('.b-product .list li').click(function () {
        $('.b-product .list li').removeClass('actif');
        $(this).addClass('actif');
        const target = $(this).data('target');
        $('.b-product .list .products-row div').hide();
        $(target).show();
    });

    /**
     * Gestion des blocs services
     */
    $('.b-service').click(function () {
        $(this).toggleClass('actif');
        $(this).children('.infos').slideToggle(300);
    });

    /**
     * Gestion filtres news
     */
    $('.blog .filtres p').click(function () {
        $(this).toggleClass('actif');
        $('.blog .filtres ul').slideToggle(300);
    });

    /**
     * Affichage news en fonction de la catégorie
     */
    $('.blog section .filtres ul a').click(function () {
        var idCat = $(this).attr('data-cat');
        $('.blog section a.more').hide(200);
        $('section .bloc-more').show(300);

        $('.blog .filtres p').toggleClass('actif');
        $('.blog .filtres ul').slideToggle(300);

        $('.blog .filtres p span').html(' : ' + $(this).html());

        $('.blog section .b-news').each(function () {
            if ($(this).attr('data-cat') == idCat) {
                $(this).show(200);
            } else {
                $(this).hide(200);
            }
        });

    });

    /**
     * Fonction scroll Top
     */
    $('footer .scroll-top').click(function () {
        $('html, body').animate({
            scrollTop: 0,
        }, 600);
    });

    /**
     * Affichage des communiqués de presse
     */
    $('section .b-annonces .more').click(function () {
        $(this).hide(200);
        $('section .bloc-more').show(300);
    });

    /**
     * Affichage des news finances
     */
    $('section .bt-actu').click(function () {
        $(this).hide(200);
        $('section .bloc-more').show(300);
    });

    /**
     * Affichage des news blog
     */
    $('.blog section a.more').click(function () {
        $(this).hide(200);
        $('section .bloc-more').show(300);
    });

    /**
     * Close message form newsletter
     */
    $('.mc4wp-alert').click(function () {
        $(this).hide(300);
    });

    /**
     * Appartition popin
     */
    $('section .b-contenu p.visite').click(function (e) {
        if (Modernizr.canvas) {
            // HTML5
            window.open(
                'https://vp.lmc-test.cz/virtualniprohlidky/latecoere/html5/kariery790.html',
                '_blank',
            );
        } else {
            // Flash
            window.open(
                'https://vp.lmc-test.cz/virtualniprohlidky/latecoere/flash/kariery790.html',
                '_blank',
            );
        }
        return false;
    });

    /**
     * Cacher popin
     */
    $('.page-template-gab-czech .popin-container .popin .close').click(function () {
        $(this).parent().parent().fadeOut();
    });

    /**
     * Cacher popin
     */
    $('.page-template-gab-czech .popin-container .overlay').click(function () {
        $(this).parent().fadeOut();
    });

    /**
     * Init lazyload
     */
    $('img.lazy').lazyload({
        effect: 'fadeIn',
    });

    /**
     * Gestion gallery
     */

    var configGallery = {
        over: showGallery, // function = onMouseOver callback (REQUIRED)
        //timeout: 500, // number = milliseconds delay before onMouseOut
        interval: 150,
        out: hideGallery, // function = onMouseOut callback (REQUIRED)
    };

    $('.gallery .pictures li').hoverIntent(configGallery);

    /**
     * Show gallery
     */
    function showGallery() {
        $(this).children('.infos').removeAttr('styles');
        var p = $(this).position();
        $(this).children('.infos').css('height', $(this).innerHeight());
        $(this).children('.infos').css('top', (p.top + $(this).innerHeight()));
        $(this).children('.infos').show(300);
    }

    /**
     * Hide gallery
     */
    function hideGallery() {
        $(this).children('.infos').hide(200);
    }

    /**
     * Gestion filtres gallery
     */
    $('.gallery .filtres p').click(function () {

        $(this).toggleClass('actif');
        $('.gallery .filtres ul').slideToggle(300);
    });

    /**
     * Affichage picture en fonction de la catégorie
     */
    $('.gallery .filtres ul a').click(function () {
        var idCat = $(this).attr('data-cat');

        $('.gallery ul.pictures li').each(function () {
            if ($(this).attr('data-cat') == idCat) {
                $(this).show(200);
            } else {
                $(this).hide(200);
            }
        });

    });

    if ($('body').hasClass('page-template-gab-czech')) {
        $(document).scroll(() => {
            var activeTab = [];
            $('.tab').each((index, element) => {
                if (($(element).offset().top - $('.header').height() - $('.page-template-gab-czech .b-onglets').height() - 1) < $(document).scrollTop()) {
                    activeTab.push($(element).attr('id'));
                }
            });
            setTimeout(() => {
                $('section .b-onglets ul li a').removeClass('actif');
                var getactive = activeTab[activeTab.length - 1] || 'about-us';
                $('section .b-onglets ul li a[href="#' + getactive + '"]').addClass('actif');
            }, 10);
        });
    }

});

/* INITIALISATION GMAP */
function HomeControl(controlDiv, map) {
    google.maps.event.addDomListener(zoomout, 'click', function () {
        var currentZoomLevel = map.getZoom();
        if (currentZoomLevel != 0) {
            map.setZoom(currentZoomLevel - 1);
        }
    });

    google.maps.event.addDomListener(zoomin, 'click', function () {
        var currentZoomLevel = map.getZoom();
        if (currentZoomLevel != 21) {
            map.setZoom(currentZoomLevel + 1);
        }
    });
}

var aMarker = window.Latecoere.implantations;

window.initMap = function () {
    if (!$('body').hasClass('page-template-gab-implantations')) {
        return;
    }
    var map;

    var v_lat = '12.453680224345833';
    var v_long = '-30.41015625';
    var v_zoom = 2;
    map = new google.maps.Map(document.getElementById('map'), {
        zoom: v_zoom,
        center: new google.maps.LatLng(v_lat, v_long),
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        styles: [{
            'featureType': 'landscape',
            'elementType': 'all',
            'stylers': [{'color': '#f3f3f8'}],
        }, {
            'featureType': 'landscape',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#f3f3f8'}],
        }, {'featureType': 'landscape', 'elementType': 'labels', 'stylers': [{'saturation': '0'}]}, {
            'featureType': 'poi',
            'elementType': 'all',
            'stylers': [{'visibility': 'off'}],
        }, {
            'featureType': 'road',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'lightness': 45}],
        }, {
            'featureType': 'road.highway',
            'elementType': 'all',
            'stylers': [{'visibility': 'simplified'}],
        }, {
            'featureType': 'road.arterial',
            'elementType': 'labels.icon',
            'stylers': [{'visibility': 'off'}],
        }, {
            'featureType': 'transit',
            'elementType': 'all',
            'stylers': [{'visibility': 'off'}],
        }, {
            'featureType': 'water',
            'elementType': 'all',
            'stylers': [{'color': '#0a2757'}, {'visibility': 'on'}],
        }],
    });

    var homeControlDiv = document.createElement('div');
    var homeControl = new HomeControl(homeControlDiv, map);

    var marker = [];
    var i = 0;
    for (var key in aMarker) {
        var data = aMarker[key];

        marker[i] = new google.maps.Marker({
            position: new google.maps.LatLng(data.latitude, data.longitude),
            map: map,
            icon: {
                url: '/app/themes/latecoere/assets/images/marker' + data.type + '.svg',
                scaledSize: new google.maps.Size(24, 34),
            },
            data: data.icon,
            optimized: false,
            type: data.type,
        });

        var savoirPlus = '';
        if (data.lien != '') {
            savoirPlus = '<a href="' + data.lien + '" target="_blank">' + __('En savoir plus') + '</a>';
        }

        var contentString = '<div class="popup type' + data.type + '"><h2>' + data.titre + '</h2><div>' + data.adresse + '</div><p>' + savoirPlus + '</div>';
        var infowindow = new google.maps.InfoWindow({
            content: contentString,
        });
        marker[i]['show'] = infowindow;

        google.maps.event.addListener(marker[i], 'click', function () {
            this['show'].open(map, this);
        });
        i++;
    }

    /**
     * Fonction show/hide marker
     */
    jQuery('.f-filtres input').change(function () {
        var typeMarker = parseInt(jQuery(this).attr('data-type'));
        var hideMarker = true;
        if (jQuery(this).is(':checked')) {
            hideMarker = false;
        }

        for (var key in marker) {

            if (parseInt(marker[key].type) === typeMarker) {
                if (hideMarker === true) {
                    marker[key].setVisible(false);
                } else {
                    marker[key].setVisible(true);
                }
            } else if (parseInt(marker[key].type) === 4) {
                //myonoffswitch1
                if (jQuery('.f-filtres input#myonoffswitch1').is(':checked') || jQuery('.f-filtres input#myonoffswitch2').is(':checked')) {
                    marker[key].setVisible(true);
                } else {
                    marker[key].setVisible(false);
                }
            }
        }

    });

};

if (window.screen.width > 992) {
    const body = document.querySelector('body');
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    const homeSection = document.querySelector('section');
    const placeholderPopinVideo = document.querySelector('.placeholder-video');
    const popinVideo = document.querySelector('.popin-video');
    const popinVideoPlayer = document.querySelector('.popin-video-player');
    const popinVideoSound = document.querySelector('.popin-video-sound');
    const popinVideoPlay = document.querySelector('.popin-video-control');

    const cookieValue = document.cookie.split('; ').find(row => row.startsWith('popinVideoClose'));

    var close = document.querySelector('.close');
    var popinVideoCTA = document.querySelector('.popin-video-cta');

    if (popinVideo) {
        if (cookieValue === 'popinVideoClose=true') {
            placeholderPopinVideo.classList.add('d-none');
            popinVideo.classList.add('d-none');
            header.classList.remove('d-none');
            footer.classList.remove('d-none');
            homeSection.classList.remove('d-none');
            body.style.overflow = 'initial';
            popinVideoPlayer.pause();
        } else {
            placeholderPopinVideo.classList.remove('d-none');
            popinVideo.classList.remove('d-none');
            header.classList.add('d-none');
            footer.classList.add('d-none');
            homeSection.classList.add('d-none');
            body.style.overflow = 'hidden';

            popinVideoPlayer.onplay = (e) => {
                placeholderPopinVideo.classList.add('d-none');
            };
        }
        close.addEventListener('click', function () {
            closePopinVideo();
        });
        popinVideoCTA.addEventListener('click', function () {
            closePopinVideo();
        });
        popinVideoSound.addEventListener('click', function () {
            popinVideoSound.classList.toggle('unmuted');
            toggleMute();
        });
        popinVideoPlay.addEventListener('click', () => {
            popinVideoPlay.classList.toggle('playing');
            if (popinVideoPlay.classList.contains('playing')) {
                popinVideoPlayer.play();
            } else {
                popinVideoPlayer.pause();
            }
        });

        popinVideoPlayer.addEventListener('ended', () => {
            popinVideoPlay.classList.remove('playing');
            popinVideoSound.classList.remove('unmuted');
        });
    }

    function closePopinVideo() {
        placeholderPopinVideo.classList.add('d-none');
        popinVideo.classList.add('d-none');
        header.classList.remove('d-none');
        footer.classList.remove('d-none');
        homeSection.classList.remove('d-none');
        body.style.overflow = 'initial';
        popinVideoPlayer.muted;
        popinVideoPlayer.pause();

        sessionStorage.setItem('popinVideoClose', 'true');
        var d = new Date();
        d.setTime(d.getTime() + 86400);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = 'popinVideoClose' + '=' + 'true' + ';' + expires + ';path=/';
    }

    function toggleMute() {
        popinVideoPlayer.muted = !popinVideoPlayer.muted;
    }

} else {
    const placeholderPopinVideo = document.querySelector('.placeholder-video');
    const popinVideo = document.querySelector('.popin-video');
    const popinVideoPlayer = document.querySelector('.popin-video-player');

    if (popinVideo) {
        placeholderPopinVideo.classList.add('d-none');
        popinVideo.classList.add('d-none');
        popinVideoPlayer.pause();
    }
}

const cookieMissionValue = document.cookie.split('; ').find(row => row.startsWith('popinMissionClose'));
const popinMission = document.querySelector('.popin-mission');

var closeMission = document.querySelector('.close-mission');
var popinMissionCTA = document.querySelector('.popin-mission-content-cta');

if (popinMission) {
    if (cookieMissionValue === 'popinMissionClose=true') {
        popinMission.classList.add('d-none');
    } else {
        popinMission.classList.remove('d-none');
    }

    closeMission.addEventListener('click', function () {
        closePopinMission();
    });
    popinMissionCTA.addEventListener('click', function () {
        closePopinMission();
    });
}

function closePopinMission() {
    popinMission.classList.add('d-none');
    sessionStorage.setItem('popinMissionClose', 'true');
    var d = new Date();
    d.setTime(d.getTime() + 86400);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = 'popinMissionClose' + '=' + 'true' + ';' + expires + ';path=/';
}

let showCmplz = document.querySelector('.show-cmplz');
let cmplzBtn  = document.querySelector('.cmplz-manage-consent');
let cmplzRow  = document.querySelector('.cmplz-cookiebanner');

if (showCmplz) {
    showCmplz.addEventListener('click', function (e) {
        if (cmplzBtn.classList.contains('cmplz-show')) {
            cmplzBtn.classList.remove('cmplz-show');
        }
        cmplzBtn.classList.add('cmplz-show');

        if (cmplzRow.classList.contains('cmplz-show')) {
            cmplzRow.classList.remove('cmplz-show');
        }
        cmplzRow.classList.remove('cmplz-dismissed');
        cmplzRow.classList.add('cmplz-show');
    })
}

